<template>
  <div>
    <el-row style="margin-top: 10px;">
      <el-col :span="20" :offset="4">
        <el-breadcrumb separator=">">
          <el-breadcrumb-item>Login</el-breadcrumb-item>
          <el-breadcrumb-item>Forgot password</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>
    <el-row style="margin-top: 50px">
      <el-col :span="18" :offset="4">
        <el-steps :active="active" align-center space="30%" process-status="finish">
          <el-step title="Confirm account number"></el-step>
          <el-step title="Reset password"></el-step>
          <el-step title="Reset successfully"></el-step>
        </el-steps>
      </el-col>
    </el-row>
    <el-row v-show="active === 0" style="margin-top: 50px">
      <el-col :span="10" :offset="6">
        <el-form ref="form" :model="form" :rules="rules" label-width="120px">
          <el-form-item label="Email" prop="email">
            <el-input placeholder="Please enter email" v-model="form.email"/>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-row v-show="active === 1" style="margin-top: 50px">
      <el-col :span="8" :offset="8">
        <el-form ref="ruleForm" :model="ruleForm" :rules="rules2" label-width="200px">
          <el-form-item label="New password" prop="newPass">
            <el-input placeholder="6-16 characters, case sensitive" v-model="ruleForm.newPass">
            </el-input>
          </el-form-item>
          <el-form-item label="Confirm password" prop="confirmPass">
            <el-input placeholder="Please enter your confirmation password" v-model="ruleForm.confirmPass">
            </el-input>
          </el-form-item>
          <el-form-item label="Verification code" prop="code">
            <el-input placeholder="Please enter the verification code" v-model="ruleForm.code">
            </el-input>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-row v-show="active === 2" style="margin-top: 50px">
      <el-col :span="5" :offset="11">
        <span>Reset password successfully~</span>
      </el-col>
    </el-row>
    <el-row style="margin-top: 20px; text-align: center">
      <el-col :span="12" :offset="6">
        <el-button v-show="active<=1" type="primary" @click="next">NEXT</el-button>
        <el-button v-show="active==2" type="primary" @click="goHome">Back to home page</el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "Forget",
  data() {
    let validateEmail = (rule, value, callback) => {
      if (!value) {
        callback(new Error('Please enter email'));
      }
      const emailRule = /\w+@\w+(\.\w+)+/;
      if (emailRule.test(value)) {
        return callback();
      } else {
        return callback(
            new Error("The mailbox format is incorrect")
        );
      }
    };
    let validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please enter password'));
      } else {
        const passPattern = /^(?!\d{6,8}$)(?! )(?=.*[a-z])(?=.*[0-9])[a-zA-Z0-9_]{6,16}$/;
        if (passPattern.test(value)) {
          this.$refs.ruleForm.validateField('confirmPass');
          return callback();
        } else {
          return callback(
              new Error("The password format is incorrect")
          );
        }
      }
    };
    let validateConfirmPass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please enter your password again'));
      } else if (value !== this.ruleForm.newPass) {
        callback(new Error('Two password inconsistencies!'));
      } else {
        callback();
      }
    };
    let validateCode = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('The verification code cannot be empty'));
      } else {
        callback();
      }
    };
    return {
      form: {
        email: ''
      },
      ruleForm: {
        newPass: '',
        confirmPass: '',
        code: ''
      },
      rules: {
        email: [{validator: validateEmail, trigger: 'blur'}]
      },
      rules2: {
        newPass: [{validator: validatePass, trigger: 'blur'}],
        confirmPass: [{validator: validateConfirmPass, trigger: 'blur'}],
        code: [{validator: validateCode, trigger: 'blur'}]
      },
      active: 0
    }
  },
  activated() {
    this.active = 0
  },
  methods: {
    goHome() {
      this.$router.replace({
        path: '/'
      })
    },
    next() {
      switch (this.active) {
        case 0:
          this.$refs['form'].validate((valid) => {
            if (valid) {
              this.$axios
                  .get("/api/mall/email/sendRestCode?email=" + this.form.email)
                  .then(res => {
                    // // “001”代表注册成功，其他的均为失败
                    if (res.data.code === 200) {
                      // 弹出通知框提示注册成功信息
                      this.notifySucceed("The verification code has been sent");
                      this.active++
                    } else {
                      // 弹出通知框提示注册失败信息
                      this.notifyError(res.data.message);
                    }
                  })
                  .catch(err => {
                    return Promise.reject(err);
                  });
            } else {
              return false;
            }
          });
          break;
        case 1:
          this.$refs['ruleForm'].validate((valid) => {
            if (valid) {
              this.$axios
                  .post("/api/mall/member/restPass", {
                    email: this.form.email,
                    password: this.ruleForm.newPass,
                    code: this.ruleForm.code
                  })
                  .then(res => {
                    if (res.data.code === 200) {
                      this.notifySucceed("Password reset");
                      this.active++
                    } else {
                      this.notifyError(res.data.message);
                    }
                  })
                  .catch(err => {
                    return Promise.reject(err);
                  });
            } else {
              return false;
            }
          });
          break;
      }

    },
  }
}
</script>

<style scoped>

</style>